@import '../../../styles/settings';
@import '../../../styles/cas/mixins';
@import '../../../Magento_Catalog/styles/printer-select-dropdown';

$svg-icons-path: '../../images/icons/';

@include callout(success);
@include callout(error);
@include callout(alert);

@include cas-btn('secondary', 'secondary', $btn-type:'solid');

.cms-home {
    .page-main {
        margin-top: 0;
    }
}

.heading {
    margin-top: 0;
}

.home-section {
    margin: 4rem auto;
}

.banner {
    background: url('../images/homepage/green-banner.jpg') no-repeat center right #31590b;
    padding: 3.5rem 0;
}

.banner-box {
    background: url('../../images/pattern-bg/paper-bg_e67921.jpg') repeat center center #e67921;
    color: $white;
    padding: 2rem;
    .brand-label, .series-selector, .model-selector {
        color: $white;
    }
    a {
        color: $white;
        background: linear-gradient(0deg, $white, $white) no-repeat left 100%;
        background-size: 0 1px;
        transition: all 0.35s;
        &:hover {
            background-size: 100% 1px;
            background-position-x: left;
        }
    }
    .printer-selector-wrapper {
        margin-top: 0;
    }
    .tag-label {
        display: inline-block;
        font-weight: 700;
        margin-bottom: 0.25rem;
    }
    .banner-header {
        font-family: $sec-font;
        font-size: rem-calc(27);
        margin: 0 0 1rem;
    }
}

.form.subscribe {
    margin-bottom: 1rem;
    .button {
        width: 100%;
        box-shadow: 0 2px 4px 0 rgba($black, .5);
    }
}
.grid {
    margin-top: 1rem;
}
.grid-item {
    width: calc(50% - 10px);
    margin-bottom: rem-calc(20);
}

.tagline {
    background: url('../images/homepage/green-banner-mobile.jpg') no-repeat bottom center #1d3506;
    color: $white;
    padding: 3rem 1rem 6rem;
    .tag-box {
        padding: 2rem 1rem;
        background: rgba(#1D3506, 0.6);
    }
    .tag-header {
        font-family: $script-font;
        font-weight: normal;
        margin: 0 0 0.5rem;
        font-size: rem-calc(30);
    }
    .tag {
        margin: 0;
    }
}
.main-content {
    .sp-wrapper {
        a {
            word-break: normal;
        }
    }
}
.sp-wrapper {
    padding: 1rem;
    background-color: get-color('light-vanilla');
    background: radial-gradient(ellipse at center, $white 60%, get-color('vanilla') 99%);
    border: 1px solid #eedeba;
    border-bottom: 5px solid lighten(get-color('light-green'), 5%);

    .sp-img {
        margin-bottom: 1rem;
    }
    blockquote, blockquote p {
        font-size: rem-calc(18);
        color: get-color('primary');
    }
    blockquote {
        padding: 0.5rem;
        &:before {
            top: rem-calc(-5);
            left: rem-calc(-10);
        }
        &:after {
            bottom: rem-calc(-25);
        }
    }
    cite {
        font-size: rem-calc(18);
        color: get-color('primary');
        font-weight: 300;
    }
}

@media screen and #{breakpoint(medium)} {
    .home-section {
        margin: 5rem auto;
    }
    .tagline {
        background: url('../../images/pattern-bg/paper-bg_559a13.jpg') repeat center center #559a13;
        padding: 1rem 0 1.5rem;
        margin-bottom: 4rem;
        .tag-box {
            padding: 0;
            background: transparent;
        }
    }
    .banner-box {
        display: flex;
        justify-content: space-between;
        .box-wrapper {
            width: 50%;
            border-right: 1px solid #A75818;
            padding-right: 2rem;
            &:last-of-type {
                border-left: 1px solid #ff923b;
                border-right: 0;
                padding-right: 0;
                padding-left: 2rem;
            }
        }
    }
    blockquote {
        padding: 2rem;
    }
    .sp-wrapper {
        padding: 1rem 2rem;
    }
    .grid {
        margin-top: 0;
    }
}

@media screen and #{breakpoint(large)} {
    .banner {
        .banner-header {
            font-size: rem-calc(32);
        }
    }
    .tagline {
        .image-wrapper {
            position: relative;
            .tree-img {
                position: absolute;
                top: rem-calc(-155);
                left: rem-calc(-40);
            }
        }
        .tag-header {
            margin-bottom: 0;
        }
    }
    .grid-item {
        width: calc(100%/3 - 15px);
    }
}
