//Valid value: alert, warning, info, success with no quote
@mixin callout($type){
    $bg-color: '';
    $color: '';
    $icon: '';
    @if ($type == 'alert' or $type == 'error'){
        $color: get-color(alert);
        $icon: url('#{$svg-icons-path}error.svg');
    } @else if($type == 'warning'){
        $color: get-color(warning);
        $icon: url('#{$svg-icons-path}warning.svg');
    } @else if($type == 'info'){
        $color: get-color(info);
        $icon: url('#{$svg-icons-path}info.svg');
    } @else if($type == 'success'){
        $color: get-color(success);
        $icon: url('#{$svg-icons-path}success.svg');
    } @else if($type == 'coupon-notice'){
        $color: get-color(success);
        $icon: url('#{$svg-icons-path}coupon.svg');
    }
    .callout.#{$type}{
        padding: $callout-padding;
        border-color: $color;
        color: $body-font-color;
        &:before{
            content: $icon;
        }
        &.closeable{
            padding-right: 2.5rem;
        }
    }
}
