.tiny-loading {
  padding-top: 0.625rem;
}
.tiny-loading.printer-selector {
  margin-top: 1rem;
}
.tiny-loading-icon {
  margin-right: 0.5rem;
}

.printer-selector-wrapper {
  text-align: left;
  margin-top: 0.5rem;
}
.printer-selector-wrapper select {
  margin-bottom: 1rem;
}
.printer-selector-wrapper .printer-selector-brand select {
  margin-bottom: 0;
}
.printer-selector-wrapper .series-selector, .printer-selector-wrapper .cartridge-selector {
  margin-top: 1rem;
}
.printer-selector-wrapper .printer-selector-tabs {
  border: 0;
  padding: 0;
  margin: 2rem 0 0;
}
.printer-selector-wrapper .selector-tab {
  background: #ffffff;
  border: 1px solid #ccc;
  border-radius: 0 0.3125rem 0.3125rem 0.3125rem;
  padding: 1rem 2rem;
}
.printer-selector-wrapper .selector-tab .brand-label, .printer-selector-wrapper .selector-tab .series-selector, .printer-selector-wrapper .selector-tab .model-selector {
  color: #333333;
}
.printer-selector-wrapper input {
  display: none;
}
.printer-selector-wrapper input + label {
  display: inline-block;
  border: 1px solid #ccc;
  border-top: 3px solid transparent;
  background: #ccc;
  padding: 0.5rem 0.75rem;
  border-radius: 0.3125rem 0.3125rem 0 0;
  position: relative;
  top: 1px;
  margin: 0 -0.4375rem 0 0;
}
.printer-selector-wrapper input + label:before, .printer-selector-wrapper input + label:after {
  display: none;
}
.printer-selector-wrapper input:checked + label {
  background: #ffffff;
  border-top: 3px solid #73bf44;
  border-bottom: 1px solid transparent;
}
.printer-selector-wrapper .button {
  margin: 1rem 0 1.5rem;
  padding-right: 0;
  padding-left: 0;
}

.callout.success {
  padding: 0.5rem 1rem 0.5rem 3.5rem;
  border-color: #00aa61;
  color: #333333;
}
.callout.success:before {
  content: url("../../images/icons/success.svg");
}
.callout.success.closeable {
  padding-right: 2.5rem;
}

.callout.error {
  padding: 0.5rem 1rem 0.5rem 3.5rem;
  border-color: #e12b1d;
  color: #333333;
}
.callout.error:before {
  content: url("../../images/icons/error.svg");
}
.callout.error.closeable {
  padding-right: 2.5rem;
}

.callout.alert {
  padding: 0.5rem 1rem 0.5rem 3.5rem;
  border-color: #e12b1d;
  color: #333333;
}
.callout.alert:before {
  content: url("../../images/icons/error.svg");
}
.callout.alert.closeable {
  padding-right: 2.5rem;
}

.button.secondary {
  background-color: #559a13;
  border-color: #559a13;
}
.button.secondary:before {
  color: #559a13;
}
.button.secondary:hover, .button.secondary:active {
  background-color: #66b817;
  border-color: #66b817;
}
.button.secondary:focus {
  outline: 2px solid transparent;
  box-shadow: inset 0 0 0 1.5px #ffffff, 0 0 0 1.5px #62b116;
}

.cms-home .page-main {
  margin-top: 0;
}

.heading {
  margin-top: 0;
}

.home-section {
  margin: 4rem auto;
}

.banner {
  background: url("../images/homepage/green-banner.jpg") no-repeat center right #31590b;
  padding: 3.5rem 0;
}

.banner-box {
  background: url("../../images/pattern-bg/paper-bg_e67921.jpg") repeat center center #e67921;
  color: #ffffff;
  padding: 2rem;
}
.banner-box .brand-label, .banner-box .series-selector, .banner-box .model-selector {
  color: #ffffff;
}
.banner-box a {
  color: #ffffff;
  background: linear-gradient(0deg, #ffffff, #ffffff) no-repeat left 100%;
  background-size: 0 1px;
  transition: all 0.35s;
}
.banner-box a:hover {
  background-size: 100% 1px;
  background-position-x: left;
}
.banner-box .printer-selector-wrapper {
  margin-top: 0;
}
.banner-box .tag-label {
  display: inline-block;
  font-weight: 700;
  margin-bottom: 0.25rem;
}
.banner-box .banner-header {
  font-family: "Roboto Slab", Cambria, "Times New Roman", serif;
  font-size: 1.6875rem;
  margin: 0 0 1rem;
}

.form.subscribe {
  margin-bottom: 1rem;
}
.form.subscribe .button {
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(51, 51, 51, 0.5);
}

.grid {
  margin-top: 1rem;
}

.grid-item {
  width: calc(50% - 10px);
  margin-bottom: 1.25rem;
}

.tagline {
  background: url("../images/homepage/green-banner-mobile.jpg") no-repeat bottom center #1d3506;
  color: #ffffff;
  padding: 3rem 1rem 6rem;
}
.tagline .tag-box {
  padding: 2rem 1rem;
  background: rgba(29, 53, 6, 0.6);
}
.tagline .tag-header {
  font-family: "Oleo Script", cursive;
  font-weight: normal;
  margin: 0 0 0.5rem;
  font-size: 1.875rem;
}
.tagline .tag {
  margin: 0;
}

.main-content .sp-wrapper a {
  word-break: normal;
}

.sp-wrapper {
  padding: 1rem;
  background-color: #fffbf7;
  background: radial-gradient(ellipse at center, #ffffff 60%, #fff6ee 99%);
  border: 1px solid #eedeba;
  border-bottom: 5px solid #81c657;
}
.sp-wrapper .sp-img {
  margin-bottom: 1rem;
}
.sp-wrapper blockquote, .sp-wrapper blockquote p {
  font-size: 1.125rem;
  color: #e67921;
}
.sp-wrapper blockquote {
  padding: 0.5rem;
}
.sp-wrapper blockquote:before {
  top: -0.3125rem;
  left: -0.625rem;
}
.sp-wrapper blockquote:after {
  bottom: -1.5625rem;
}
.sp-wrapper cite {
  font-size: 1.125rem;
  color: #e67921;
  font-weight: 300;
}

@media screen and (min-width: 48em) {
  .home-section {
    margin: 5rem auto;
  }
  .tagline {
    background: url("../../images/pattern-bg/paper-bg_559a13.jpg") repeat center center #559a13;
    padding: 1rem 0 1.5rem;
    margin-bottom: 4rem;
  }
  .tagline .tag-box {
    padding: 0;
    background: transparent;
  }
  .banner-box {
    display: flex;
    justify-content: space-between;
  }
  .banner-box .box-wrapper {
    width: 50%;
    border-right: 1px solid #A75818;
    padding-right: 2rem;
  }
  .banner-box .box-wrapper:last-of-type {
    border-left: 1px solid #ff923b;
    border-right: 0;
    padding-right: 0;
    padding-left: 2rem;
  }
  blockquote {
    padding: 2rem;
  }
  .sp-wrapper {
    padding: 1rem 2rem;
  }
  .grid {
    margin-top: 0;
  }
}
@media screen and (min-width: 64em) {
  .banner .banner-header {
    font-size: 2rem;
  }
  .tagline .image-wrapper {
    position: relative;
  }
  .tagline .image-wrapper .tree-img {
    position: absolute;
    top: -9.6875rem;
    left: -2.5rem;
  }
  .tagline .tag-header {
    margin-bottom: 0;
  }
  .grid-item {
    width: calc(33.3333333333% - 15px);
  }
}