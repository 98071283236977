.tiny-loading{
    padding-top: rem-calc(10);
    &.printer-selector{
        margin-top: 1rem;
    }
    &-icon {
        margin-right: 0.5rem;
    }
}
.printer-selector-wrapper {
    text-align: left;
    margin-top: 0.5rem;
    select {
        margin-bottom: 1rem;
    }
    .printer-selector-brand {
        select {
            margin-bottom: 0;
        }
    }
    .series-selector, .cartridge-selector {
        margin-top: 1rem;
    }
    .printer-selector-tabs {
        border: 0;
        padding: 0;
        margin: 2rem 0 0;
    }
    .selector-tab {
        background: $white;
        border: $global-border;
        border-radius: 0 $global-radius $global-radius $global-radius;
        padding: 1rem 2rem;
        .brand-label, .series-selector, .model-selector {
            color: $dark-gray;
        }
    }
    input {
        display: none;
        + label {
            display: inline-block;
            border: $global-border;
            border-top: 3px solid transparent;
            background: $light-gray;
            padding: rem-calc(8 12);
            border-radius: $global-radius $global-radius 0 0;
            position: relative;
            top: 1px;
            margin: rem-calc(0 -7 0 0);
            &:before, &:after {
                display: none;
            }
        }
        &:checked + label {
            background: $white;
            border-top: 3px solid get-color('light-green');
            border-bottom: 1px solid transparent;
        }
    }
    .button {
        margin: 1rem 0 1.5rem;
        padding-right: 0;
        padding-left: 0;
    }
}
